import { ExportToCsv } from 'export-to-csv';
import { useState } from 'react';
import { $path } from 'remix-routes';

import { PageDisplayName } from '../../app/components/DynamicPage/utils';
import {
  AdminPublicLibraryLayout,
  useAdminPublicLibraryData,
} from '../../app/components/PublicLibrary/AdminPublicLibraryLayout';
import { PublicLibraryBreadcrumbs } from '../../app/components/PublicLibrary/PublicLibraryBreadcrumbs';
import { PageManagerUtils } from '../components/PageManager/utils';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { apiService } from '../services/api-service';

export async function clientLoader() {
  return {};
}

export function Component() {
  const { pageName } = useAdminPublicLibraryData();

  const [progress, setProgress] = useState<string>('');

  const { call: handleClick, state } = useLiveAsyncCall(async () => {
    setProgress('');

    const page = (await apiService.page.get(pageName)).data.page;
    const tags = PageManagerUtils.GetTags(page);
    const tagIds = tags.map((t) => t.id);
    const groupedPacks = (
      await apiService.gamePack.getGroupedGamePacksByTags(tagIds)
    ).data.groupedGamePacks;
    const packs = Object.values(groupedPacks).flatMap((p) => p);

    const rows = [];
    let maxBrandsCount = 0;
    const seenPackIds = new Set<string>();
    for (let i = 0; i < packs.length; i++) {
      setProgress(`${i + 1} / ${packs.length}`);
      const pack = packs[i];

      if (seenPackIds.has(pack.id)) continue;
      seenPackIds.add(pack.id);

      const row: unknown[] = [];

      row.push(
        ...[
          pack.id,
          pack.name,
          pack.playbackSettings?.gameMakeup ?? '',
          pack.playbackSettings?.makeUnitsFrom ?? '',
          pack.playbackSettings?.defaultUnitsPerSession ?? '',
          pack.playbackSettings?.instructionRules ?? '',
          pack.playbackSettings?.leaderboardRules ?? '',
          pack.playbackSettings?.randomizeUnitOrder ?? '',
          pack.playbackSettings?.resumeFromLastUnitPlayed ?? '',
        ]
      );

      const { brands = [], blocks = [] } = (
        await apiService.gamePack.getGamePackById(pack.id, {
          brands: true,
        })
      ).data;
      const seenBrandIds = new Set<string>();
      for (const childId of pack.childrenIds || []) {
        const block = blocks.find((b) => b.id === childId);
        if (!block) continue;
        const brand = brands.find((b) => b.id === block.brandId);
        if (!brand) continue;
        if (seenBrandIds.has(brand.id)) continue;
        seenBrandIds.add(brand.id);
        row.push(brand.name);
      }
      maxBrandsCount = Math.max(maxBrandsCount, brands.length);

      rows.push(row);
    }

    const header = [
      'Game Pack Id',
      'Game Pack Name',
      'Game Makeup',
      'Make Units From',
      'Default Units Per Session',
      'Instruction Rules',
      'Leaderboard Rules',
      'Random Game Order',
      'Resume from last Game played',
    ];
    for (let i = 0; i < maxBrandsCount; i++) {
      header.push(`Brand ${i + 1}`);
    }
    rows.unshift(header);
    for (const row of rows) {
      while (row.length < header.length) {
        row.push('');
      }
    }

    const exporter = new ExportToCsv({
      filename: `${PageDisplayName(pageName)} Game Packs`,
    });
    exporter.generateCsv(rows);

    return null;
  });

  return (
    <AdminPublicLibraryLayout>
      <div className='w-full h-full p-10 text-white'>
        <PublicLibraryBreadcrumbs
          items={[
            {
              label: 'Explore',
              kind: 'link',
              to: $path('/admin/public-library/:name', {
                name: pageName,
              }),
            },
            { label: 'Export Game Packs', kind: 'noop' },
          ]}
        />

        <div className='mt-10 max-w-100'>
          Download a CSV file containing all the game packs in "
          {PageDisplayName(pageName)}" and their associated brands.
        </div>

        <button
          type='button'
          onClick={handleClick}
          disabled={state.state.isRunning}
          className='mt-2 btn-primary w-50 h-10'
        >
          {state.state.isRunning ? 'Downloading...' : 'Download'}
        </button>
        {progress && <div className='mt-1 text-sms'>Progress: {progress}</div>}
        {state.error && (
          <div className='mt-1 text-red-500'>{state.error.message}</div>
        )}
      </div>
    </AdminPublicLibraryLayout>
  );
}
